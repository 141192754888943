import React, { useEffect, useCallback } from 'react';

import { App as Widget } from '@amzn/staffing-assignments-user-library-web-app';
import { UserStaffingAssignment } from '../graphql/generated';
import useComponentRenderer from './useComponentRenderer';
import mapWidgetAssignment from '../utils/mapWidgetAssignment';
import { ClientIdentifier, clientTypeMap, Environment } from '../types';

export interface UseWidgetInterface {
  assignment?: UserStaffingAssignment;
  clientIdentifier: ClientIdentifier;
  workstationAuthorized: boolean;
  nonEnforcementDisplayed?: boolean;
  requireAssociateAssignment?: boolean;
  setWorkstationAuthorized: (workstationAuthorized: boolean) => void;
  onAssignmentAccepted?: (staffingAssignment?: UserStaffingAssignment) => void;
  onAssignmentDisplayed?: (associateId: string) => void;
  environment: Environment;
}

export const unauthorizedPopUpDelay = 5000;

const useWidget = ({
  assignment,
  clientIdentifier,
  onAssignmentAccepted,
  onAssignmentDisplayed,
  workstationAuthorized,
  nonEnforcementDisplayed,
  setWorkstationAuthorized,
  environment,
  requireAssociateAssignment,
}: UseWidgetInterface) => {
  const { removeComponent: removeWidget, renderComponent } =
    useComponentRenderer('saul-widget');

  const onAssignmentAcceptedCallback = (
    assignment?: UserStaffingAssignment
  ) => {
    removeWidget();
    onAssignmentAccepted?.(assignment);
  };

  const renderWidget = useCallback(() => {
    if (!assignment && !requireAssociateAssignment) {
      return;
    }
    const { workstation, processSegment, associateId } = { ...assignment };
    const widgetAssignment = assignment
      ? mapWidgetAssignment(
          workstation,
          processSegment,
          associateId,
          clientIdentifier
        )
      : undefined;

    renderComponent(
      <Widget
        assignment={widgetAssignment}
        clientType={clientTypeMap[clientIdentifier]}
        clientIdentifier={clientIdentifier}
        onAccept={() => onAssignmentAcceptedCallback(assignment)}
        unauthorized={!workstationAuthorized}
        nonEnforcementDisplayed={nonEnforcementDisplayed}
        nodeId={assignment?.nodeId}
        environment={environment}
      />
    );
    associateId && onAssignmentDisplayed?.(associateId);

    if (!workstationAuthorized) {
      setTimeout(() => {
        setWorkstationAuthorized(true);
        removeWidget();
        assignment && onAssignmentAccepted?.(assignment);
      }, unauthorizedPopUpDelay);
    }
  }, [
    assignment,
    clientIdentifier,
    onAssignmentAccepted,
    onAssignmentDisplayed,
    renderComponent,
    workstationAuthorized,
    nonEnforcementDisplayed,
    environment,
    requireAssociateAssignment,
  ]);

  useEffect(() => {
    if (!assignment && !requireAssociateAssignment) {
      removeWidget();
    }
    return () => {
      removeWidget();
    };
  }, [removeWidget, assignment, requireAssociateAssignment]);

  return {
    renderWidget,
  };
};

export default useWidget;
