import { useCallback, useEffect, useRef, useState } from 'react';
import { Client, gql } from 'urql';
import {
  GetStaffingAssignmentsDocument,
  StaffingAssignment,
} from '../graphql/generated';

gql`
  query GetStaffingAssignments($nodeId: String!) {
    getStaffingAssignments(nodeId: $nodeId) {
      nodeId
      associateId
      workstationId
      processSegmentId
      source
      locked
      lastUpdateUser
      updatedTime
    }
  }
`;

type StaffingAssignmentsResult = {
  staffingAssignments?: StaffingAssignment[];
  loading: boolean;
  error?: unknown;
};

const FETCH_INTERVAL_MS = 30000;

interface UseStaffingAssignmentsForNodeProps {
  client: Client;
  nodeId: string;
  disabled?: boolean;
}

const useStaffingAssignmentsForNode = ({
  client,
  nodeId,
  disabled = false,
}: UseStaffingAssignmentsForNodeProps): StaffingAssignmentsResult => {
  const [staffingAssignments, setStaffingAssignments] =
    useState<StaffingAssignment[]>();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<unknown | undefined>();
  const fetchInterval = useRef<number | undefined>();

  const fetchStaffingAssignments = useCallback(async () => {
    setLoading(true);

    try {
      const result = await client.query(
        GetStaffingAssignmentsDocument,
        { nodeId },
        { requestPolicy: 'network-only' }
      );
      if (result.error || !result.data) {
        setError(
          `An error occurred fetching the assignments: ${result.error?.message ?? 'No data'}`
        );
      } else {
        setStaffingAssignments(result.data.getStaffingAssignments);
        setError(undefined);
      }
    } catch (e) {
      setError(e);
    } finally {
      setLoading(false);
    }
  }, [client, nodeId]);

  useEffect(() => {
    if (!disabled) {
      fetchStaffingAssignments();
      fetchInterval.current = window.setInterval(
        fetchStaffingAssignments,
        FETCH_INTERVAL_MS
      );
    }

    return () => {
      clearInterval(fetchInterval.current);
    };
  }, [fetchInterval, fetchStaffingAssignments, disabled]);

  return {
    staffingAssignments,
    loading,
    error,
  };
};

export default useStaffingAssignmentsForNode;
