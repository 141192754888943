const ERROR_MESSAGE = 'Invalid JWT';

const isJwtExpired = (token: string): boolean => {
  const tokenParts = token.split('.');
  if (tokenParts.length !== 3) {
    throw new Error(ERROR_MESSAGE);
  }
  const tokenPayload = decodePayload(tokenParts[1]);
  if (typeof tokenPayload.exp !== 'number') {
    throw new Error(ERROR_MESSAGE);
  }
  return Date.now() >= tokenPayload.exp * 1000;
};

const decodePayload = (payloadString: string): { exp: unknown } => {
  try {
    const decodedPayloadString = atob(payloadString);
    return JSON.parse(decodedPayloadString);
  } catch (e) {
    throw new Error(`${ERROR_MESSAGE}: ${e}`);
  }
};

export default isJwtExpired;
