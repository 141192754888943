import { ClientIdentifier } from './StaffingAssignments';
import { ClientType } from '@amzn/staffing-assignments-user-library-web-app';

export enum WorkstationType {
  CONTAINER_BUILD = 'CONTAINER_BUILD',
  DIRECTED_LOADER = 'DIRECTED_LOADER',
  PROCESS_ASSISTANT = 'PROCESS_ASSISTANT',
  LEARNING_AMBASSADOR = 'LEARNING_AMBASSADOR',
  QUALITY = 'QUALITY',
  CARDINAL_GATEKEEPER = 'CARDINAL_GATEKEEPER',
  JACKPOT_OPERATOR = 'JACKPOT_OPERATOR',
  PROBLEM_SOLVE_PROCESSING = 'PROBLEM_SOLVE_PROCESSING',
  PROBLEM_SOLVE_GATEKEEPER = 'PROBLEM_SOLVE_GATEKEEPER',
}

export interface Workstation {
  workstationId: string;
  workstationType: WorkstationType;
  inputLocations: string[];
  outputLocations: string[];
}

export const workstationToClientIdentifier: Record<string, ClientIdentifier> = {
  INDUCT: 'LUMOS',
  FLUID_UNLOAD: 'LUMOS',
  ULD_UNLOAD: 'LUMOS',
  CONTAINER_BUILD: 'SAM',
  WATERSPIDER: 'SAM',
  LOADER: 'SAM',
  DIRECTED_LOADER: 'SAM',
  UNDIRECTED_LOADER: 'SAM',
  CONTAINER_STAGING: 'SAM',
  CONTAINER_UNLOAD: 'SAM',
  PROBLEM_SOLVE_GATEKEEPER: 'SAM',
  DIRECTED_COLLECTOR: 'SAM',
  CHASE: 'SAM',
};

export const clientTypeMap: Record<ClientIdentifier, ClientType> = {
  SAM: 'mobile',
  DIRECTED_PALLETIZE: 'mobile',
  LUMOS: 'kiosk',
  ARTEMIS: 'kiosk',
  GTDR: 'kiosk',
  INDIRECT: 'indirect',
  UNKNOWN: 'indirect',
};
