import { useFetchWithAuth } from './useFetchWithAuth';
import { useMemo } from 'react';
import { Client, fetchExchange } from 'urql';
import { CoreMetadata } from '../types';

/**
 * Vends a GraphQL client that points to the WattWebsite authorized GraphQL server
 */
export const useGraphQLClient = (metadata: CoreMetadata) => {
  const fetchWithAuth = useFetchWithAuth(metadata);

  return useMemo<Client>(
    () =>
      new Client({
        url: '/graphql',
        fetch: fetchWithAuth,
        exchanges: [fetchExchange],
      }),
    [fetchWithAuth]
  );
};
