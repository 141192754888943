import {
  Assignment,
  NO_ASSIGNMENT,
} from '@amzn/staffing-assignments-user-library-web-app';
import { ClientIdentifier, clientTypeMap } from '../types';
import { ProcessSegment, WorkstationDefinition } from '../graphql/generated';
import { getClientIdentifier } from './workstationUtils';

const mapWidgetAssignment = (
  workstation: WorkstationDefinition | undefined | null,
  processSegment: ProcessSegment | undefined | null,
  associateId: string = NO_ASSIGNMENT,
  clientIdentifier: ClientIdentifier = 'UNKNOWN'
): Assignment => {
  const { workstationType, workstationName } = workstation || {};
  const { processSegmentName, workstationsType } = processSegment || {};
  const location = workstationName ?? undefined;
  const type = workstationType || workstationsType || '';
  const identifier = getClientIdentifier(
    workstation,
    processSegment,
    clientIdentifier
  );
  const clientType = clientTypeMap[identifier];
  return {
    name: processSegmentName || type.replace(/_/g, ' ').toLowerCase(),
    location,
    clientIdentifier: identifier,
    clientType,
    associateId,
  };
};

export default mapWidgetAssignment;
