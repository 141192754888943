import { Environment } from './Environment';
import { Workstation } from './Workstation';

export enum SAUL_ACTIONS {
  WORKSTATION_ID = 'WORKSTATION_ID',
  INTERRUPTIBLE = 'INTERRUPTIBLE',
  ASSOCIATES = 'ASSOCIATES',
  ROLE = 'ROLE',
}

export interface StaffingAssignment {
  nodeId: string;
  associateId: string;
  workstations: Workstation[];
}

export interface FcMenuAuthenticationData {
  fcMenuCookie: string;
}

export interface LumosKioskAuthenticationData {
  bearerTokenProvider: () => Promise<string>;
}

export interface RightStationAuthenticationData {
  rightStationTokenProvider: string | (() => Promise<string>);
}

export type AuthenticationData =
  | FcMenuAuthenticationData
  | LumosKioskAuthenticationData
  | RightStationAuthenticationData;

export const isFcMenuAuthenticationData = (
  authenticationData: AuthenticationData
): authenticationData is FcMenuAuthenticationData => {
  return (
    (authenticationData as FcMenuAuthenticationData).fcMenuCookie !== undefined
  );
};

export const isLumosKioskAuthenticationData = (
  authenticationData: AuthenticationData
): authenticationData is LumosKioskAuthenticationData => {
  return (
    (authenticationData as LumosKioskAuthenticationData).bearerTokenProvider !==
    undefined
  );
};

export const isRightStationAuthenticationData = (
  authenticationData: AuthenticationData
): authenticationData is RightStationAuthenticationData => {
  return (
    (authenticationData as RightStationAuthenticationData)
      .rightStationTokenProvider !== undefined
  );
};

export type ClientIdentifier =
  | 'SAM'
  | 'DIRECTED_PALLETIZE'
  | 'LUMOS'
  | 'ARTEMIS'
  | 'GTDR'
  | 'INDIRECT'
  | 'UNKNOWN';

export interface CoreMetadata {
  environment: Environment;
  authenticationData: AuthenticationData;
}

export interface Metadata extends CoreMetadata {
  clientIdentifier: ClientIdentifier;
}

export type SaulStoreType = {
  associateId?: string | string[];
  workstationId?: string;
  interruptible: boolean;
  role?: string;
};

export interface WorkstationIdAction {
  type: SAUL_ACTIONS.WORKSTATION_ID;
  workstationId: string;
}

export interface AssociatesAction {
  type: SAUL_ACTIONS.ASSOCIATES;
  associates: string | string[];
}

export interface InterruptibleAction {
  type: SAUL_ACTIONS.INTERRUPTIBLE;
  interruptible: boolean;
}

export interface RoleAction {
  type: SAUL_ACTIONS.ROLE;
  role?: string;
}

export type SAULActionType =
  | WorkstationIdAction
  | AssociatesAction
  | InterruptibleAction
  | RoleAction;
