import { SAUL_ACTIONS, SaulStoreType, SAULActionType } from '../types';

export const initialState: SaulStoreType = {
  associateId: '',
  workstationId: '',
  interruptible: true,
};

/**
 * Reducer function for managing the state of the workstation.
 *
 * @param state - The current state of the workstation.
 * @param action - The action object that describes the state change.
 * @returns The new state of the workstation.
 */
export const saulReducer = (
  state: SaulStoreType,
  action: SAULActionType
): SaulStoreType => {
  const { INTERRUPTIBLE, WORKSTATION_ID, ROLE } = SAUL_ACTIONS;
  switch (action.type) {
    /**
     * Pause user interruptions
     */
    case INTERRUPTIBLE:
      return { ...state, interruptible: action.interruptible as boolean };
    /**
     * Set the workstation ID
     * */
    case WORKSTATION_ID:
      return { ...state, workstationId: action.workstationId };
    case ROLE:
      return { ...state, role: action.role };
    default:
      return state;
  }
};
