import React, { useCallback, useEffect, useState } from 'react';
import { useStaffingAssignments } from './hooks/useStaffingAssignments';
import { AuthenticationData, Environment } from './types';
import { UserStaffingAssignment } from './graphql/generated';

const App: React.FC<unknown> = () => {
  const [authToken, setAuthToken] = useState('');
  const [clientIdentifier, setClientIdentifier] = useState();
  const [environment, setEnvironment] = useState();
  const [associate, setAssociate] = useState();
  const [authType, setAuthType] = useState();
  const [workstation, setWorkstation] = useState();
  const [interruptible, setInterruptibleFromEvent] = useState(false);

  const [onPending, setOnPending] = useState<(associateId: string) => void>(
    () => () => console.log('onPending')
  );
  const [onAccept, setOnAccept] = useState<
    (assignment?: UserStaffingAssignment) => void
  >(() => () => console.log('onAccept'));
  const [onDisplayed, setOnDisplayed] = useState<(associateId: string) => void>(
    () => () => console.log('onDisplayed')
  );

  const getDataFromEvent = useCallback((event: MessageEvent) => {
    if (event.data.authToken) {
      setAuthToken(event.data.authToken);
      setClientIdentifier(event.data.clientIdentifier);
      setEnvironment(event.data.environment);
      setAssociate(event.data.associate);
      setAuthType(event.data.authType);
      setWorkstation(event.data.workstationId);
      setInterruptibleFromEvent(event.data.interruptible);
      setOnPending(
        () => () =>
          event?.source?.postMessage('onPending', {
            targetOrigin: event.origin,
          })
      );
      setOnAccept(
        () => () =>
          event?.source?.postMessage('onAccept', { targetOrigin: event.origin })
      );
      setOnDisplayed(
        () => () =>
          event?.source?.postMessage('onDisplayed', {
            targetOrigin: event.origin,
          })
      );
    }
  }, []);

  useEffect(() => {
    window.addEventListener('message', getDataFromEvent);
    return () => window.removeEventListener('message', getDataFromEvent);
  }, [getDataFromEvent]);

  const authData: AuthenticationData =
    authType === 'fcmenu'
      ? { fcMenuCookie: authToken }
      : { rightStationTokenProvider: authToken };

  const { setWorkstationId, setInterruptible } = useStaffingAssignments({
    interruptible: interruptible,
    metadata: {
      authenticationData: authData,
      clientIdentifier: clientIdentifier ?? 'UNKNOWN',
      environment: environment ?? Environment.DISABLED,
    },
    associateId: associate,
    onAssignmentPending: onPending,
    onAssignmentAccepted: onAccept,
    onAssignmentDisplayed: onDisplayed,
    workstationId: workstation,
  });

  useEffect(() => {
    workstation && setWorkstationId(workstation);
  }, [workstation]);

  useEffect(() => {
    setInterruptible(interruptible);
  }, [interruptible]);

  return <>{!authToken && <text>Auth token must be provided </text>}</>;
};

export default App;
