import { ProcessSegment, WorkstationDefinition } from '../graphql/generated';
import { ClientIdentifier, workstationToClientIdentifier } from '../types';

const isDPWorkstation = (
  workstation: WorkstationDefinition | undefined | null
): boolean => {
  const maxAssociates = workstation?.maxAssociates ?? 0;
  return maxAssociates > 1 && maxAssociates < 100;
};

export const getClientIdentifier = (
  workstation: WorkstationDefinition | undefined | null,
  processSegment: ProcessSegment | undefined | null,
  currentClient: ClientIdentifier
): ClientIdentifier => {
  const type =
    workstation?.workstationType || processSegment?.workstationsType || '';

  // Container build could be either SAM, or DP. We can use maxAssociates to differentiate
  if (type === 'CONTAINER_BUILD') {
    if (isDPWorkstation(workstation)) {
      return 'DIRECTED_PALLETIZE';
    }
    if (
      currentClient === 'SAM' &&
      processSegment?.workstations?.some((ws) => !isDPWorkstation(ws))
    ) {
      return 'SAM';
    }
    if (processSegment?.workstations?.some(isDPWorkstation)) {
      return 'DIRECTED_PALLETIZE';
    }
  }

  const indirect = workstation
    ? isIndirectWorkstation(workstation)
    : processSegment && isIndirectProcessSegment(processSegment);
  const identifier = workstationToClientIdentifier[type] || 'UNKNOWN';

  return indirect ? 'INDIRECT' : identifier;
};

export const isIndirectWorkstation = (
  workstation: WorkstationDefinition
): boolean => {
  return (
    (!Array.isArray(workstation.inputLocationIds) ||
      workstation.inputLocationIds.length == 0) &&
    (!Array.isArray(workstation.outputLocationIds) ||
      workstation.outputLocationIds.length == 0)
  );
};

export const isIndirectProcessSegment = (
  processSegment: ProcessSegment
): boolean => {
  return processSegment.workstations.some(isIndirectWorkstation);
};
