import { UserStaffingAssignment } from 'src/graphql/generated';
import { WorkstationType } from '../types';

const exemptWorkstationTypes = [
  WorkstationType.CARDINAL_GATEKEEPER,
  WorkstationType.JACKPOT_OPERATOR,
  WorkstationType.LEARNING_AMBASSADOR,
  WorkstationType.PROBLEM_SOLVE_GATEKEEPER,
  WorkstationType.PROBLEM_SOLVE_PROCESSING,
  WorkstationType.PROCESS_ASSISTANT,
  WorkstationType.QUALITY,
];

export const isExemptFromEnforcement = (
  trainedRoles: string[] | undefined,
  assignment?: UserStaffingAssignment
): boolean => {
  const workstationType =
    assignment?.processSegment?.workstationsType ||
    assignment?.workstation?.workstationType;

  return (
    trainedRoles?.includes(WorkstationType.PROCESS_ASSISTANT) ||
    (workstationType &&
      exemptWorkstationTypes.includes(workstationType as WorkstationType)) ||
    !!workstationType?.split('_').includes('PA')
  );
};
