import { useCallback, useState } from 'react';
import { Client, gql } from 'urql';
import { AcknowledgeAssignmentDocument } from '../graphql/generated';

gql`
  mutation AcknowledgeAssignment($nodeId: String, $associateId: String!) {
    acknowledgeAssignment(nodeId: $nodeId, associateId: $associateId)
  }
`;

interface UseAcknowledgeAssignmentProps {
  client: Client;
}

const useAcknowledgeAssignment = ({
  client,
}: UseAcknowledgeAssignmentProps) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<unknown | undefined>();

  const acknowledgeAssignment = useCallback(
    (associateId: string) => {
      setLoading(true);
      client
        .mutation(AcknowledgeAssignmentDocument, { associateId })
        .toPromise()
        .catch(setError)
        .finally(() => setLoading(false));
    },
    [client]
  );

  return {
    acknowledgeAssignment,
    loading,
    error,
  };
};

export default useAcknowledgeAssignment;
