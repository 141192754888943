import { ReactNode, useCallback, useRef } from 'react';

import { createRoot, Root } from 'react-dom/client';

type ComponentRef = {
  container: HTMLDivElement;
  root: Root;
};

const useComponentRenderer = (widgetId: string) => {
  const componentRef = useRef<ComponentRef>();

  const renderComponent = useCallback(
    (component: ReactNode) => {
      if (!componentRef.current) {
        const container = document.createElement('div');
        container.id = widgetId;
        document.body.appendChild(container);
        const root = createRoot(container);

        componentRef.current = { container, root };
      }

      componentRef.current.root.render(component);
    },
    [componentRef, widgetId]
  );

  const removeComponent = useCallback(() => {
    if (componentRef.current) {
      componentRef.current.root.unmount();
      document.body.removeChild(componentRef.current.container);
      componentRef.current = undefined;
    }
  }, [componentRef]);

  return {
    renderComponent,
    removeComponent,
  };
};

export default useComponentRenderer;
