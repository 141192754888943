import React from 'react';
import ReactDOM from 'react-dom/client';
import {
  useStaffingAssignments,
  StaffingAssignmentsReturnType,
} from './hooks/useStaffingAssignments';
import { useStaffingBoard } from './hooks/useStaffingBoard';
import { Environment } from './types';
import { UserStaffingAssignment } from './graphql/generated';

import App from './App';
const root =
  document.getElementById('saulRoot') &&
  ReactDOM?.createRoot(document.getElementById('saulRoot') as HTMLElement);
root?.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);
/**
 * This module exports the `useStaffingAssignments` function and the `Environment` enum.
 *
 * @module StaffingAssignmentsUserLibrary
 */
export {
  useStaffingAssignments,
  useStaffingBoard,
  Environment,
  type UserStaffingAssignment,
  type StaffingAssignmentsReturnType,
};
