import { useCallback, useEffect, useRef, useState } from 'react';
import { Client, gql } from 'urql';
import {
  GetProcessSegmentsDocument,
  ProcessSegment,
} from '../graphql/generated';

gql`
  query GetProcessSegments($nodeId: String!) {
    getProcessSegments(nodeId: $nodeId) {
      processSegmentId
      processSegmentName
      workstationsType
      workstations {
        workstationId
        workstationAlias
        workstationName
        workstationType
        inputLocationIds
        outputLocationIds
        creationType
        status
        maxAssociates
        requiredEquipment
      }
      costBucket
    }
  }
`;

type ProcessSegmentsResult = {
  processSegments?: ProcessSegment[];
  loading: boolean;
  error?: unknown;
};

const FETCH_INTERVAL_MS = 3600000;

interface UseProcessSegmentsForNodeProps {
  client: Client;
  nodeId: string;
  disabled?: boolean;
}

const useProcessSegmentsForNode = ({
  client,
  nodeId,
  disabled = false,
}: UseProcessSegmentsForNodeProps): ProcessSegmentsResult => {
  const [processSegments, setProcessSegments] = useState<ProcessSegment[]>();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<unknown | undefined>();

  const fetchInterval = useRef<number | undefined>();

  const fetchProcessSegments = useCallback(async () => {
    setLoading(true);

    try {
      const result = await client.query(
        GetProcessSegmentsDocument,
        { nodeId },
        { requestPolicy: 'network-only' }
      );
      if (result.error || !result.data) {
        setError(
          `An error occurred fetching the process segments: ${result.error?.message ?? 'No data'}`
        );
      } else {
        setProcessSegments(result.data.getProcessSegments);
        setError(undefined);
      }
    } catch (e) {
      setError(e);
    } finally {
      setLoading(false);
    }
  }, [client, disabled, nodeId]);

  useEffect(() => {
    if (!disabled) {
      fetchProcessSegments();
      fetchInterval.current = window.setInterval(
        fetchProcessSegments,
        FETCH_INTERVAL_MS
      );
    }

    return () => {
      clearInterval(fetchInterval.current);
    };
  }, [fetchInterval, fetchProcessSegments, disabled]);

  return {
    processSegments,
    loading,
    error,
  };
};

export default useProcessSegmentsForNode;
