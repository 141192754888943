import { useCallback, useRef } from 'react';
import {
  AuthenticationData,
  CoreMetadata,
  Environment,
  isFcMenuAuthenticationData,
  isLumosKioskAuthenticationData,
  isRightStationAuthenticationData,
} from '../types';
import isJwtExpired from '../utils/isJwtExpired';

const WATT_WEBSITE_URL_BY_ENVIRONMENT: Map<Environment, string> = new Map([
  [Environment.BETA, 'https://beta.wattwebsite.sorttech.amazon.dev'],
  [Environment.NA_PROD, 'https://na.prod.wattwebsite.sorttech.amazon.dev'],
  [Environment.EU_PROD, 'https://eu.prod.wattwebsite.sorttech.amazon.dev'],
]);

type AuthConfig = {
  path: 'fcmenu' | 'kiosk' | 'rightStation';
  bearerToken?: string;
};

const getAuthConfig = async (
  authenticationData: AuthenticationData
): Promise<AuthConfig> => {
  if (isFcMenuAuthenticationData(authenticationData)) {
    return {
      path: 'fcmenu',
      bearerToken: authenticationData.fcMenuCookie,
    };
  }
  if (isLumosKioskAuthenticationData(authenticationData)) {
    return {
      path: 'kiosk',
      bearerToken: await authenticationData.bearerTokenProvider(),
    };
  }
  if (isRightStationAuthenticationData(authenticationData)) {
    return {
      path: 'rightStation',
    };
  }

  throw new Error('Invalid authentication data');
};
/**
 * Fetch from authorized WattWebsite endpoints with the appropriate headers attached based on the client
 */
const useFetchWithAuth = ({
  authenticationData,
  environment,
}: CoreMetadata) => {
  const csrfToken = useRef<string>();
  const csrfTokenPromise = useRef<Promise<string>>();
  const csrfTokenRequired = !isLumosKioskAuthenticationData(authenticationData);
  const rightStationAuthToken = useRef<string>();

  const authDataFlattened = Object.entries(authenticationData).flat();

  /**
   * Fetch from WattWebsite authorized endpoints
   */
  const fetchWithAuth = useCallback(
    async (
      url: RequestInfo | URL,
      options: RequestInit = {}
    ): Promise<Response> => {
      if (environment === Environment.DISABLED) {
        throw Error('SAUL is disabled');
      }

      const authConfig = await getAuthConfig(authenticationData);
      const fullUrl = `${WATT_WEBSITE_URL_BY_ENVIRONMENT.get(environment)}/${authConfig.path}${url}`;

      let { bearerToken } = authConfig;
      if (isRightStationAuthenticationData(authenticationData)) {
        if (
          !rightStationAuthToken.current ||
          isJwtExpired(rightStationAuthToken.current)
        ) {
          rightStationAuthToken.current =
            typeof authenticationData.rightStationTokenProvider === 'string'
              ? authenticationData.rightStationTokenProvider
              : await authenticationData.rightStationTokenProvider?.();
        }

        bearerToken = rightStationAuthToken.current;
      }

      options.headers = {
        ...options.headers,
        Authorization: `Bearer ${bearerToken}`,
      };

      return fetch(fullUrl, {
        credentials: 'include',
        ...options,
      });
    },
    [...authDataFlattened, environment, rightStationAuthToken]
  );

  /**
   * Fetch the CSRF token
   */
  const fetchCsrfToken = useCallback(async (): Promise<string> => {
    const response = await fetchWithAuth('/csrfToken');
    if (!response.ok) {
      csrfTokenPromise.current = undefined;
      throw Error(response.statusText);
    }
    return await response.text();
  }, [fetchWithAuth]);

  return useCallback(
    async (
      url: RequestInfo | URL,
      options: RequestInit = {}
    ): Promise<Response> => {
      if (csrfTokenRequired) {
        if (!csrfToken.current && !csrfTokenPromise.current) {
          // initialize the CSRF token asynchronously since this callback is used by multiple hooks
          csrfTokenPromise.current = fetchCsrfToken();
        }

        if (csrfTokenPromise.current) {
          csrfToken.current = await csrfTokenPromise.current;
          csrfTokenPromise.current = undefined;
        }

        if (csrfToken.current) {
          options.headers = {
            ...options.headers,
            'anti-csrftoken-a2z': csrfToken.current,
          };
        }
      }

      return fetchWithAuth(url, options);
    },
    [csrfToken, fetchCsrfToken, fetchWithAuth]
  );
};

export { useFetchWithAuth };
