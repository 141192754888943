import { useCallback, useEffect, useRef, useState } from 'react';
import { Client, gql } from 'urql';
import { GetRightStationNodeConfigurationDocument } from '../graphql/generated';

gql`
  query GetRightStationNodeConfiguration($nodeId: String) {
    getRightStationNodeConfiguration(nodeId: $nodeId) {
      enabledFeatures
    }
  }
`;

type GetNodeConfigurationResult = {
  enabledFeatures?: string[];
  loading: boolean;
  error?: unknown;
};

const FETCH_INTERVAL_MS = 90000;

interface UseNodeConfigurationProps {
  client: Client;
  disabled?: boolean;
}

const useNodeConfiguration = ({
  client,
  disabled = false,
}: UseNodeConfigurationProps): GetNodeConfigurationResult => {
  const [enabledFeatures, setEnabledFeatures] = useState<string[]>();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<unknown | undefined>();
  const fetchInterval = useRef<number | undefined>();

  const getNodeDetails = useCallback(async () => {
    setLoading(true);
    try {
      const result = await client.query(
        GetRightStationNodeConfigurationDocument,
        {},
        { requestPolicy: 'network-only' }
      );
      if (result.error || !result.data) {
        throw new Error(
          `An error occurred fetching the node configuration: ${result.error?.message ?? 'No data'}`
        );
      }
      setEnabledFeatures(
        result.data.getRightStationNodeConfiguration?.enabledFeatures
      );
      setError(undefined);
    } catch (e) {
      setError(e);
    } finally {
      setLoading(false);
    }
  }, [client]);

  useEffect(() => {
    if (!disabled) {
      getNodeDetails();
      fetchInterval.current = window.setInterval(
        getNodeDetails,
        FETCH_INTERVAL_MS
      );
    }

    return () => {
      clearInterval(fetchInterval.current);
    };
  }, [disabled, fetchInterval, getNodeDetails]);

  return {
    enabledFeatures,
    loading,
    error,
  };
};

export default useNodeConfiguration;
