import { useCallback } from 'react';
import { Client, gql } from 'urql';
import { GetAssociateUsernameDocument } from '../graphql/generated';

gql`
  query getAssociateUsername($nodeId: String!, $badgeBarcodeId: String!) {
    associateUsername(nodeId: $nodeId, badgeBarcodeId: $badgeBarcodeId) {
      username
    }
  }
`;

type FetchAssociateUsernameResult = (badgeBarcodeId: string) => Promise<{
  username?: string;
  error?: unknown;
}>;

interface UseAssociateUsernameProps {
  client: Client;
  nodeId: string;
  disabled?: boolean;
}

const useFetchAssociateUsername = ({
  client,
  nodeId,
  disabled = false,
}: UseAssociateUsernameProps): FetchAssociateUsernameResult => {
  return useCallback(
    async (badgeBarcodeId: string) => {
      if (disabled) {
        return Promise.reject('SAUL is disabled');
      }

      return client
        .query(GetAssociateUsernameDocument, { nodeId, badgeBarcodeId })
        .then(({ data, error }) => ({
          username: data?.associateUsername?.username,
          error,
        }));
    },
    [client, disabled, nodeId]
  );
};

export default useFetchAssociateUsername;
